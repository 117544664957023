import request from '@/utils/request'

// 入库
export function importInventoryCards(data) {
  return request({
    url: `/sim_card_inventories/import`,
    method: 'post',
    data
  })
}

// 重新入库
export function reImportInventoryCards(data) {
  return request({
    url: `/sim_card_inventories/re_import`,
    method: 'post',
    data
  })
}

// 查询库存卡
export function findSimCardInventories(data) {
  return request({
    url: `/sim_card_inventories`,
    method: 'post',
    data
  })
}

// 查询库存卡详情
export function findSimCardInventory(id) {
  return request({
    url: `/sim_card_inventories/${id}`,
    method: 'get'
  })
}

// 删除库存卡
export function destroyInventoryCards(data) {
  return request({
    url: `/batch_operations/sim_card_inventories`,
    method: 'delete',
    data
  })
}

// 更新库存卡 运营商数据
export function updateSimCardInventoryCarrierData(id) {
  return request({
    url: `/sim_card_inventories/${id}/carrier_data`,
    method: 'post'
  })
}

// 导出库存卡
export function exportSimCardInventories(data) {
  return request({
    url: `/sim_card_inventories/export`,
    method: 'post',
    data
  })
}
