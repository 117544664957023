<template>
  <div>
    <search-sim-card-inventory @submit="submitSearch" />

    <div class="simiot-table-operation-buttons">
      <a-space>
        <a-button
          type="primary"
          @click="showImportModal"
          v-if="isShowMenu('sim_card_inventories/import')"
        >入库</a-button>

        <a-dropdown placement="bottomCenter">
          <a-menu slot="overlay" @click="handleBatchOperation">
            <a-menu-item
              v-for="option in batchOperationOptions"
              :key="option.value"
            >
              {{ option.label }}
            </a-menu-item>
          </a-menu>
          <a-button type="primary"> 批量操作 <a-icon type="down" /> </a-button>
        </a-dropdown>
        <a-button type="primary" @click="exportData">导出</a-button>
      </a-space>
    </div>

    <a-table
      size="middle"
      :scroll="{ x: 'max-content' }"
      :columns="columns"
      :data-source="data"
      :loading="loading"
      :pagination="false"
      @change="sortChange"
      :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
      row-key="iccid"
    >
      <div slot="iccid" slot-scope="iccid, record">
        <a @click="showInfoModal(record.id)">{{ iccid }}</a>
      </div>
    </a-table>

    <pagination
      v-show="pagination.total_count > 0"
      :total-count="pagination.total_count"
      :page.sync="query.page"
      :per-page.sync="query.per_page"
      @change="fetchData"
    />

    <!-- 入库操作 -->
    <import-sim-card-inventory
      v-if="isShowImportModal"
      :purchase-order-id="0"
      :visible.sync="isShowImportModal"
    />

    <!-- 详情模态框 -->
    <show-sim-card-inventory
      v-if="isShowInfoModal"
      :visible.sync="isShowInfoModal"
      :id="showingId"
    />

    <!-- 删除库存卡模态框 -->
    <destroy-sim-card-inventory
      v-if="isShowDestroyModal"
      :visible.sync="isShowDestroyModal"
      :iccids="selectedRowKeys"
    />

    <!-- 数据更新库存卡模态框 -->
    <update-sim-card-inventories-info
      v-if="isShowUpdateInfoModal"
      :visible.sync="isShowUpdateInfoModal"
      :iccids="selectedRowKeys"
    />

    <!-- 变更运营商套餐模态框 -->
    <change-carrier-product
      v-if="isShowChangeCarrierProductModal"
      :visible.sync="isShowChangeCarrierProductModal"
      :iccids="selectedRowKeys"
      :is-inventory="true"
    />
  </div>
</template>

<script>
import { hasPermission } from '@/utils/permission'
import { findSimCardInventories, exportSimCardInventories } from '@/api/sim_card_inventory'
import SearchSimCardInventory from '@/views/sim_card_inventories/Search'
import Pagination from '@/components/Pagination'
import { findInventoryBatchOperationOptions } from '@/api/batch_operation'
import { exportExcel } from '@/api/excel'

export default {
  name: 'SimCardInventoryList',
  components: {
    SearchSimCardInventory,
    Pagination,
    ImportSimCardInventory: () => import('@/views/sim_card_inventories/import'),
    ShowSimCardInventory: () => import('@/views/sim_card_inventories/Show'),
    DestroySimCardInventory: () => import('@/views/batch_operations/DestroyInventory'),
    UpdateSimCardInventoriesInfo: () => import('@/views/batch_operations/UpdateSimCardInventoriesInfo'),
    ChangeCarrierProduct: () => import('@/views/batch_operations/ChangeSimCardsCarrierProduct')
  },
  data() {
    return {
      data: [],
      loading: true,
      isShowImportModal: false, // 是否显示入库表单弹窗
      isShowInfoModal: false, // 是否显示详情弹窗
      isShowDestroyModal: false, // 是否显示删除卡弹窗
      isShowUpdateInfoModal: false, // 是否显示数据更新卡弹窗
      isShowChangeCarrierProductModal: false, // 是否显示变更运营商套餐模态框
      showingId: 0, // 正在查看详情的记录id
      batchOperationOptions: findInventoryBatchOperationOptions(), // 批量操作选项
      selectedRowKeys: [],
      pagination: {
        total_count: 0
      },
      query: {},
      sort: {
        sort_field: '',
        sort_order: ''
      }
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    columns() {
      return [
        {
          title: 'ICCID/SIMID',
          dataIndex: 'iccid',
          fixed: 'left',
          sorter: true,
          sortOrder: this.sort.sort_field === 'iccid' ? this.sort.sort_order : false,
          scopedSlots: { customRender: 'iccid' }
        },
        {
          title: '手机号码',
          dataIndex: 'phone_number'
        },
        {
          title: 'IMSI',
          dataIndex: 'imsi'
        },
        {
          title: '短号',
          dataIndex: 'short_number'
        },
        {
          title: '运营商种类',
          dataIndex: 'carrier_type'
        },
        {
          title: '运营商账户',
          dataIndex: 'carrier_account_name'
        },
        {
          title: 'VPDN分组ID',
          dataIndex: 'vpdn_id'
        },
        {
          title: '运营商套餐',
          dataIndex: 'carrier_product_name'
        },
        {
          title: 'APN参数',
          dataIndex: 'apn_parameters'
        },
        {
          title: '运营商状态',
          dataIndex: 'card_status'
        },
        {
          title: '卡片材质',
          dataIndex: 'card_type',
          ellipsis: true
        },
        {
          title: '卡片尺寸',
          dataIndex: 'card_shape',
          ellipsis: true
        },
        {
          title: '超套停',
          dataIndex: 'is_exceed_package_stop'
        },
        {
          title: '入库时间',
          dataIndex: 'imported_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'imported_at' ? this.sort.sort_order : false
        },
        {
          title: '运营商测试期',
          dataIndex: 'carrier_test_period_end_date'
        },
        {
          title: '运营商沉默期',
          dataIndex: 'carrier_silent_period_end_date'
        },
        {
          title: '运营商激活时间',
          dataIndex: 'carrier_activated_at'
        },
        {
          title: '数据更新时间',
          dataIndex: 'data_updated_at',
          sorter: true,
          sortOrder: this.sort.sort_field === 'data_updated_at' ? this.sort.sort_order : false
        }
      ]
    }
  },
  methods: {
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    handleBatchOperation(e) {
      switch (e.key) {
        case 'delete' :
          this.isShowDestroyModal = true
          break
        case 'update_info' :
          this.isShowUpdateInfoModal = true
          break
        case 'change_carrier_product' :
          this.isShowChangeCarrierProductModal = true
          break
      }
    },

    showInfoModal(id) {
      this.showingId = id
      this.isShowInfoModal = true
    },

    isShowMenu(menu) {
      return hasPermission(menu)
    },

    showImportModal() {
      this.isShowImportModal = true
    },

    submitSearch(search) {
      // 重置排序
      this.sort = this.$options.data().sort
      // 赋值搜索条件，保留 per_page
      this.query = Object.assign({},
        this.$options.data().query,
        { per_page: this.query.per_page },
        search)
      this.fetchData()
    },

    sortChange(pagination, filters, sorter) {
      this.sort.sort_field = sorter.field
      this.sort.sort_order = sorter.order
      // 跳转第一页
      this.query.page = 1
      this.fetchData()
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findSimCardInventories(Object.assign({}, this.query, this.sort)).then((res) => {
        this.data = res.data
        this.pagination = res.pagination
        this.loading = false
      })
    },

    exportData() {
      exportSimCardInventories(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0 && res.data.is_direct_download) {
          exportExcel(res.data.id, res.data.file_name)
        }
      })
    }
  }
}
</script>
